import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { Flex, Box } from 'rebass'

import { graphql } from 'gatsby'

import Helmet from '../components/Helmet'
import Header from '../components/blocks/Header'
import Footer from '../components/blocks/Footer'
import CaseStudyLarge from '../components/blocks/CaseStudyLarge'
import CaseStudySmall from '../components/blocks/CaseStudySmall'
import Heading from '../components/elements/Heading'
import ScrollAnimationWrapper from '../components/elements/ScrollAnimationWrapper'
import theme from '../themes'

const StyledCaseStudiesPage = styled(Box)`
  max-width: 1168px;
  position: relative;

  ${Box} {
    max-width: 1168px;
  }

  .link-text {
    position: relative;
    padding-left: 2em;

    &:before {
      position: absolute;
      content: '';
      top: 50%;
      left: 0;
      border-radius: 100vw;
      background: #ff7a7a;
      width: 20px;
      height: 5px;
      transform: translate(0, -50%);
    }
  }

  h1 {
    font-size: 40px;

    @media (min-width: 374px) {
      font-size: 45px;
    }

    @media (min-width: ${props => props.theme.breakpoints[1]}) {
      font-size: 60px;
    }

    @media (min-width: ${props => props.theme.breakpoints[2]}) {
      font-size: 80px;
    }
  }
`

const CaseStudies = ({ data }) => {
  const query = data.contentfulCaseStudiesPage
  const { metaTitle, metaDescription, metaFeaturedImage } = query.seoMetadata

  return (
    <ThemeProvider theme={theme}>
      <Helmet
        title={metaTitle}
        description={metaDescription.metaDescription}
        metaImage={metaFeaturedImage && metaFeaturedImage.file.url}
        canonical="https://powershifter.com/case-studies/"
      />
      <ScrollAnimationWrapper>
        <Header variant="transparent" hasLightBackground />
        <StyledCaseStudiesPage mx={[30, null, null, 'auto']}>
          <Flex mb={0} mt={7} mx="auto">
            <Box>
              <Heading is="h1" mb={[5, 5, 7, 9]} mt={[5, 5, 5, 7]} postsymbol="" px={[0, 0, 0, 2, 0]}>{query.heading}</Heading>
              {query.caseStudiesLarge.map(caseStudy => (
                <CaseStudyLarge
                  key={caseStudy.id}
                  className={caseStudy.class}
                  backgroundImageDesktop={caseStudy.backgroundImageDesktop}
                  backgroundImageMobile={caseStudy.backgroundImageMobile}
                  backgroundColor={caseStudy.backgroundColor}
                  backgroundColorOpacity={caseStudy.backgroundColorOpacity}
                  googleTag={caseStudy.googleTag}
                  hero={caseStudy.image}
                  logo={caseStudy.logo}
                  linkTo={`${caseStudy.ctaLink}/`}
                  linkText={caseStudy.ctaText}
                  title={caseStudy.title}
                />
              ))}
              <Box mx="auto" width={[1, 1, 7 / 8, 7 / 8]}>
                <Heading fontSize={[2, 2, 4, 4]} mx="auto" postsymbol="" is="h2" textAlign="center">{query.subheading}</Heading>
              </Box>
              <Flex mt={[5, 5, 7, 7]} flexWrap="wrap" justifyContent="space-between">
                {query.caseStudiesSmall.map(caseStudy => (
                  <CaseStudySmall
                    key={caseStudy.id}
                    className={caseStudy.class}
                    description={caseStudy.description.description}
                    linkPath={`${caseStudy.linkPath}/`}
                    linkText={caseStudy.linkText}
                    heading={caseStudy.heading}
                    hero={caseStudy.image}
                    jumpToContactForm={caseStudy.jumpToContactForm}
                  />
                ))}
              </Flex>
            </Box>
          </Flex>
        </StyledCaseStudiesPage>
        <Footer />
      </ScrollAnimationWrapper>
    </ThemeProvider>
  )
}

export default CaseStudies

export const query = graphql`
  {
    contentfulCaseStudiesPage {
      caseStudiesLarge {
        id
        backgroundColor
        backgroundImageDesktop {
          description
          fluid(quality: 75) {
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
          file {
            url
          }
        }
        backgroundImageMobile {
          description
          fluid(quality: 75) {
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
          file {
            url
          }
        }
        class
        ctaLink
        ctaText
        image {
          description
          fluid(quality: 75) {
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
          file {
            url
          }
        }
        logo {
          description
          file {
            url
          }
        }
        title
      }
      caseStudiesSmall {
        id
        class
        description {
          description
        }
        heading
        image {
          description
          fluid(quality: 75) {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
          file {
            url
          }
        }
        linkPath
        linkText
        jumpToContactForm
      }
      heading
      seoMetadata {
        metaDescription {
          metaDescription
        }
        metaFeaturedImage {
          file {
            url
          }
          description
        }
        metaTitle
      }
      subheading
    }
  }
`
