import React from 'react'
import StyledFullHeightBox from './style'

const FullHeightBox = ({
  children, my, p, pl, pr, pt, px, width
}) => (
  <StyledFullHeightBox my={my} p={p} pl={pl} pr={pr} pt={pt} px={px} width={width}>
    {children}
  </StyledFullHeightBox>
)

export default FullHeightBox
