import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import {
  Flex, Box, Image, Text
} from 'rebass'
import { Link } from 'gatsby'

import FullHeightBox from '../../elements/FullHeightBox'
import RelativeBox from '../../elements/RelativeBox'
import Heading from '../../elements/Heading'
import StyledCircle from '../../elements/StyledCircle'
import StyledCaseStudy from './style'
import useScreenWidth from '../../../hooks/useScreenWidth'

const CaseStudyLarge = ({
  backgroundColor,
  backgroundImageDesktop,
  backgroundImageMobile,
  className,
  logo,
  linkTo,
  linkText,
  hero,
  title
}) => {
  const screenWidth = useScreenWidth()
  const isMobile = screenWidth && screenWidth < 576
  const backgroundImage = isMobile && backgroundImageMobile ? backgroundImageMobile : backgroundImageDesktop

  return (
    <StyledCaseStudy
      className={className}
      backgroundColor={backgroundColor}
    >
      <Link className="screener-case-study img-link" id={`${className}-case-study`} to={linkTo}>
        <Img
          alt={backgroundImage.description}
          className="case-study-bg"
          fluid={{
            ...backgroundImage.fluid
          }}
        />
      </Link>
      <Flex
        className="static case-study-content"
        flexWrap="wrap"
        flexDirection={['column-reverse', 'column-reverse', 'row', 'row']}
        p={[0, 0, 5, 5]}
      >
        <FullHeightBox
          className="static"
          pt={[5, 5, 0, 0]}
          px={[4, 4, 0, 0]}
          width={[1, 1, 1 / 2, 15 / 32]}
        >
          <Flex
            className="static"
            flexDirection="column"
            flexWrap="wrap"
            justifyContent="space-between"
          >
            <Image
              className="case-study-logo"
              alt={logo.description}
              mb={[4, 4, 0, 0]}
              src={logo.file.url}
              mr="auto"
            />
            <Heading
              className="case-study-title"
              data-aos="fade-in-load"
              fontSize={[3, 3, 3, 4]}
              mb={[4, 4, 0, 0]}
              postsymbol=""
            >
              {title}
            </Heading>
            <Box className="cta">
              <div data-aos="fade-in-load">
                <Link to={linkTo}>
                  <Flex alignItems="center">
                    <StyledCircle mr="20px" rightarrow>
                      <Image
                        alt="down arrow icon"
                        src="/images/downarrow.svg"
                      />
                    </StyledCircle>
                    <Text className="link-text" to={linkTo}>
                      {linkText}
                    </Text>
                  </Flex>
                </Link>
              </div>
            </Box>
          </Flex>
        </FullHeightBox>
        <Box
          className="hero-wrapper"
          p={[3, 3, 0, 0]}
          width={[1, 1, 1 / 2, 17 / 32]}
        >
          <RelativeBox>
            <Img
              alt={hero.description}
              className="hero"
              fluid={{
                ...hero.fluid
              }}
            />
          </RelativeBox>
        </Box>
      </Flex>
    </StyledCaseStudy>
  )
}

CaseStudyLarge.defaultProps = {
  className: undefined
}

CaseStudyLarge.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  backgroundImageDesktop: PropTypes.shape({
    description: PropTypes.string.isRequired,
    fluid: PropTypes.object.isRequired
  }).isRequired,
  backgroundImageMobile: PropTypes.shape({
    description: PropTypes.string.isRequired,
    fluid: PropTypes.object.isRequired
  }).isRequired,
  className: PropTypes.string,
  logo: PropTypes.shape({
    description: PropTypes.string.isRequired,
    file: PropTypes.shape({
      url: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  linkTo: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  hero: PropTypes.shape({
    description: PropTypes.string.isRequired,
    fluid: PropTypes.object.isRequired
  }).isRequired,
  title: PropTypes.string.isRequired
}

export default CaseStudyLarge
