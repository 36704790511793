import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Img from 'gatsby-image'
import {
  Box, Flex, Image, Text
} from 'rebass'
import { Link } from 'gatsby'
import Heading from '../../elements/Heading'
import StyledCaseStudy from './style'
import StyledCircle from '../../elements/StyledCircle'
import FadeIn from '../../elements/FadeIn'

const CaseStudySmall = ({
  className,
  description,
  linkPath,
  linkText,
  heading,
  hero,
  jumpToContactForm
}) => (
  <StyledCaseStudy
    className={className}
    mb={7}
    width={[1, 1, 15 / 32, 15 / 32]}
  >
    {jumpToContactForm ? (
      <AnchorLink id={`${className}-case-study`} href="#bottom">
        <Img
          alt={hero.description}
          fluid={{
            ...hero.fluid
          }}
        />
      </AnchorLink>
    ) : (
      <Link className="screener-case-study" id={`${className}-case-study`} to={linkPath}>
        <Img
          alt={hero.description}
          fluid={{
            ...hero.fluid
          }}
        />
      </Link>
    )}
    <Heading mb={2} mt={4} fontSize={[2, 2, 2, 3]} postsymbol="">
      {heading}
    </Heading>
    <Text className="description" fontSize={[0, 0, 1, 1]}>
      {description}
    </Text>
    <FadeIn delay={200}>
      {jumpToContactForm ? (
        <Box className="cta anchor">
          <AnchorLink id={`${className}-case-study-cta`} href="#bottom">
            <Flex alignItems="center" mt={3}>
              <StyledCircle mr="20px" rightarrow>
                <Image alt="right arrow icon" src="/images/downarrow.svg" />
              </StyledCircle>
              <Text className="link-text" href="#bottom">
                {linkText}
              </Text>
            </Flex>
          </AnchorLink>
        </Box>
      ) : (
        <Box className="cta">
          <Link id={`${className}-case-study-cta`} to={linkPath}>
            <Flex alignItems="center" mt={3}>
              <StyledCircle mr="20px" rightarrow>
                <Image alt="right arrow icon" src="/images/downarrow.svg" />
              </StyledCircle>
              <Text className="link-text">{linkText}</Text>
            </Flex>
          </Link>
        </Box>
      )}
    </FadeIn>
  </StyledCaseStudy>
)

export default CaseStudySmall
