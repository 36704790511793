import styled from 'styled-components'
import { Box } from 'rebass'

const StyledCaseStudy = styled(Box)`
  align-self: start;
  max-width: 551px !important;

  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    min-height: 455px;
  }

  @media (min-width: ${props => props.theme.breakpoints[1]}) and (max-width: ${props => props.theme.breakpoints[3]}) {
    &:nth-of-type(odd) {
      padding-left: 16px;
    }
    &:nth-of-type(even) {
      padding-right: 16px;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints[2]}) {
    min-height: 585px;
  }

  &:nth-of-type(2) {
    @media (min-width: ${props => props.theme.breakpoints[1]}) {
      padding-top: 100px;
      min-height: 555px;
    }

    @media (min-width: ${props => props.theme.breakpoints[2]}) {
      min-height: 685px;
    }
  }

  &:nth-of-type(3) {
    @media (min-width: ${props => props.theme.breakpoints[1]}) {
      margin-top: -100px;
    }
  }

  a {
    color: #020202;
    font-weight: 700;
    text-decoration: none;
  }

  .cta {
    max-width: 235px;

    &.anchor {
      max-width: 325px;
    }
  }

  h2 {
    letter-spacing: -2px;
  }
`

export default StyledCaseStudy
