import styled from 'styled-components'
import { Flex } from 'rebass'

const StyledCaseStudy = styled.div`
  margin-bottom: 100px;
  margin-left: -30px;
  margin-right: auto;
  max-width: 1082px;
  position: relative;
  width: calc(100% + 60px);

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    background-color: ${props => props.backgroundColor};
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
    height: 450px;
    margin-bottom: 100px;
    margin-left: auto;
    width: 100%;
  }

  @media (min-width: ${props => props.theme.breakpoints[2]}) {
    height: 564px;
  }

  a {
    color: #000;
    font-size: 15px;
    font-weight: 700;
    text-decoration: none;

    @media (min-width: ${props => props.theme.breakpoints[1]}) {
      color: #ffffff;
    }
  }

  .case-study-bg {
    height: 285px;
    position: absolute !important;
    right: 0;
    top: 0;
    overflow: hidden;
    z-index: 1;
    width: 100%;

    img {
      left: auto !important;
      right: 0 !important;
      width: auto !important;
    }

    @media (min-width: ${props => props.theme.breakpoints[1]}) {
      height: 100%;
    }
  }

  .case-study-content {
    pointer-events: none;
  }

  h2 {
    letter-spacing: -2px;

    @media (min-width: ${props => props.theme.breakpoints[2]}) {
      letter-spacing: -2.75px;
    }
  }

  .img-link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 285px;

    @media (min-width: ${props => props.theme.breakpoints[1]}) {
      height: 100%;
    }
  }

  .case-study-logo {
    position: absolute;
    top: 30px;
    left: 30px;
    z-index: 1;

    @media (min-width: ${props => props.theme.breakpoints[1]}) {
      display: block;
      position: static;
    }
  }

  .case-study-title {
    color: #020202;
    pointer-events: none !important;
  }

  .cta {
    margin: 0 auto;
    max-width: 235px;
    transition-delay: 0.4s !important;

    @media (min-width: ${props => props.theme.breakpoints[1]}) {
      margin: 0;
      position: static;
      transform: none;
    }
  }

  .hero-wrapper {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.17);
    height: 285px;
    position: relative;

    &:after {
      content: '';
      background-color: ${props => props.backgroundColor};
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;

      @media (min-width: ${props => props.theme.breakpoints[1]}) {
        content: none;
      }
    }

    @media (min-width: ${props => props.theme.breakpoints[1]}) {
      background-color: transparent;
      box-shadow: none;
      height: auto;
    }

    .hero {
      margin-bottom: 0;
      position: absolute !important;
      top: 0;
      right: 0;
      width: auto;
      z-index: 1;

      @media (min-width: ${props => props.theme.breakpoints[1]}) {
        height: auto;
        max-width: 100%;
        position: static !important;
      }
    }
  }

  .static {
    position: static !important;

    @media (min-width: ${props => props.theme.breakpoints[1]}) {
      position: relative !important;
    }
  }

  ${Flex} {
    height: 100%;
    position: relative;
    z-index: 1;
  }

  &.dc {
    .case-study-logo {
      max-width: 80px;

      @media (min-width: 374px) {
        max-width: 105px;
      }

      @media (min-width: ${props => props.theme.breakpoints[1]}) {
        max-width: 151px;
      }
    }

    .case-study-title {
      @media (min-width: ${props => props.theme.breakpoints[1]}) {
        color: #fff;
      }
    }

    .hero {
      right: -10px;
      top: 20px;
      width: 225px;

      @media (min-width: 321px) {
        right: -10px;
        top: 15px;
      }

      @media (min-width: ${props => props.theme.breakpoints[1]}) {
        margin-top: -50px;
        max-width: none;
        height: auto;
        max-height: none;
        width: auto;
      }

      @media (min-width: ${props => props.theme.breakpoints[2]}) {
        margin-top: -110px;
      }
    }
  }

  &.gw {
    .case-study-bg {
      opacity: 0.2;
    }

    .case-study-logo {
      left: 30px;
      max-width: 60px;
      top: 30px;

      @media (min-width: 374px) {
        max-width: 96px;
      }

      @media (min-width: ${props => props.theme.breakpoints[1]}) {
        margin-left: 0;
      }

      @media (min-width: ${props => props.theme.breakpoints[3]}) {
        max-width: 161px
      }
    }

    .case-study-title {
      @media (min-width: ${props => props.theme.breakpoints[1]}) {
        color: #fff;
      }
    }

    .hero {
      width: 249px;
      top: 10px;
      right: 0px;

      @media (min-width: ${props => props.theme.breakpoints[1]}) {
        max-width: none;
        height: auto;
        max-height: none;
        width: auto;
      }
    }
  }

  &.mnp {
    a,
    h2 {
      color: #020202;
    }

    .case-study-logo {
      left: calc(50% - 138px);
      max-width: 115px;
      top: 30px;
      z-index: 1;

      @media (min-width: ${props => props.theme.breakpoints[1]}) {
        margin-left: 0;
      }

      @media (min-width: ${props => props.theme.breakpoints[3]}) {
        max-width: 144px;
      }
    }

    .hero {
      width: 277px;
      right: 50%;
      transform: translateX(50%);
      top: 50px;

      @media (min-width: ${props => props.theme.breakpoints[1]}) {
        right: 0;
        transform: none;
        margin-top: 120px;
        max-width: none;
        height: auto;
        max-height: none;
        width: auto;
      }
    }
  }

  &.bcgov {
    a,
    h2 {
      color: #020202;
    }
    .case-study-logo {
      max-width: 146px;
      @media (min-width: ${props => props.theme.breakpoints[2]}) {
        max-width: 275px;
      }
    }
    .case-study-bg img {
      max-width: 100%;
      width: 100% !important;
      height: auto !important;
      top: 70px !important;
      @media (min-width: ${props => props.theme.breakpoints[0]}) {
        height: 100% !important;
        right: 0;
        width: 100% !important;
      }
      @media (min-width: ${props => props.theme.breakpoints[1]}) {
        top: 0 !important;
        width: auto !important;
      }
    }
    .hero {
      @media (max-width: ${props => props.theme.breakpoints[1]}) {
        width: 340px !important;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  [data-aos="fade-in-load"] {
    opacity: 0;
    transition-delay: 0.2s;
    transition-duration: 1s;

    &.aos-animate {
      opacity: 1;
    }
  }

  @media (max-width: 767px) {
    [data-aos="fade-in-load"] {
      opacity: 0;
      transition-delay: 0;
      transition-duration: 0.5s;

      &.aos-animate {
        opacity: 1;
      }
    }
  }
`

export default StyledCaseStudy
