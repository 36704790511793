import React from 'react'
import StyledRelativeBox from './style'

const RelativeBox = ({ children, className, width }) => (
  <StyledRelativeBox className={className} width={width}>
    {children}
  </StyledRelativeBox>
)

export default RelativeBox
