import { useState, useEffect } from 'react'

export default () => {
  const [screenWidth, setScreenWidth] = useState(undefined)

  useEffect(() => {
    const onResize = () => setScreenWidth(window.innerWidth)
    onResize()
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [])

  return screenWidth
}
